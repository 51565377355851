<template>
  <v-card :loading="loading" class="pa-4 pa-sm-8" color="transparent" elevation="0">
    <h2 class="access-header">Welcome Back!</h2>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate()" id="login-form" class="v-text-field--rounded form">
      <v-text-field
        ref="username"
        :loading="loading"
        v-model="email"
        :rules="emailRules"
        label="Email"
        outlined
        required></v-text-field>
      <v-text-field
        autocomplete="new-password"
        v-model="password"
        :loading="loading"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        label="Password"
        outlined
        @click:append="showPassword = !showPassword"
        required></v-text-field>
      <v-btn
        type="submit"
        color="secondary"
        block
        depressed
        x-large
        class="mr-4"
        :disabled="loading"
        :loading="loading">
        <template v-slot:loader>
          <span>Logging In...</span>
        </template>
        Login
      </v-btn>
<!--      <div class="mt-5 text-center">-->
<!--        New User? <router-link :to="{name: 'register'}">Register</router-link>-->
<!--      </div>-->
      <v-alert text outlined color="error" icon="mdi-fire" v-if="error" class="mt-5">
        {{ error }}
      </v-alert>
    </v-form>
  </v-card>
</template>

<script>
import { loginFn, getMeFn } from '../helpers/authentication'
export default {
  data: () => ({
    valid: true,
    loading: false,
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    showPassword: false,
    error: null
  }),
  methods: {
    validate () {
      if (this.$refs.form.validate()) {
        this.login()
      }
    },
    async login () {
      try {
        this.loading = true
        const token = await loginFn(this.email, this.password)
        if (token) {
          await this.$store.dispatch('setAuth', token.data.token)
          await getMeFn('AdminDashboard')
        }
      } catch (e) {
        this.$snackbar.showMessage({ content: e.response?.data?.message || 'Login Issue' })
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    username () {
      this.error = null
    },
    password () {
      this.error = null
    }
  }
}
</script>
<style lang="scss" scoped>
  .access-header {
    text-align: center;
    padding: 1.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.75rem;
  }
  @media (max-width: 960px) {
    .access-header {
      font-size: 1.25rem;
    }
  }
</style>
