<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col :md="7" :lg="6" :xl="4" :sm="8" :cols="12">
        <div class="d-flex flex-column align-center">
          <v-img :src="$vuetify.theme.dark ? require('@/assets/images/logo.png') : require('@/assets/images/logo.png')" width="45"/>
        </div>
        <transition name="fade" appear>
          <login-form />
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '../components/LoginForm'
export default {
  components: {
    LoginForm
  }
}
</script>

<style scoped>

</style>
